import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './styles/PrivateBnB.css';

const DEFAULT_IMAGE = '/default-image.jpg';

// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <>
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span> // Filled star
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span> // Empty star
      ))}
    </>
  );
};

const PrivateBnB = () => {
  const [homes, setHomes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Search filter states
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [religionFilter, setReligionFilter] = useState(''); // State for religion filter

  useEffect(() => {
    document.title = 'Khah | Bed & Breakfast';

    axios
    .get('https://khah-184f534d3b81.herokuapp.com/api/private_homes')
    .then((response) => {
        setHomes(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError('Failed to fetch homes. Please check your network connectivity and refresh.');
        setLoading(false);
        console.error(err);
      });
  }, []);

  const applyFilters = (home) => {
    return (
      !home.isBooked &&
      home.location.toLowerCase().includes(locationFilter.toLowerCase()) &&
      (bedroomFilter ? home.numberOfBedrooms >= bedroomFilter : true) &&
      (priceFilter ? home.price <= priceFilter : true) &&
      (religionFilter ? home.religion === religionFilter : true)
    );
  };

  const filteredHomes = homes.filter(applyFilters);

  if (loading) {
    return <div>Loading stays...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="private-bnb-container">
      <div className="private-bnb-container-heading">
        <h3>Private Local Stays</h3>
        <p>Browse through locally hosted private homes for your next stay.</p>
      </div>

      {/* Search Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
        <select
          value={religionFilter}
          onChange={(e) => setReligionFilter(e.target.value)}
        >
          <option value="">All Religions</option>
          <option value="Christian">Christian</option>
          <option value="Muslim">Muslim</option>
          <option value="Secular">Secular</option>
        </select>
      </div>

      {/* Button to create a new private home */}
      <div className="create-new-home-container">
        <Link to="/private_home/create" className="create-new-home-button">
          + Upload a Bed & Breakfast
        </Link>
      </div>

      {filteredHomes.length > 0 ? (
        <div className="private-stays-list">
          {filteredHomes.map(({ _id, location, numberOfBedrooms, price, rating, images, description }) => (
            <NavLink
              to={`/private_homes/${_id}`}
              key={_id}
              className="stay-card"
              onClick={() => {
                ReactGA.event({
                  category: 'Navigation',
                  action: 'View Home Details',
                  label: `Home ID: ${_id}`,
                });
              }}
            >
              <img
                src={images[0] || DEFAULT_IMAGE}
                alt={description || 'Home image'}
                className="stay-image"
              />
              <h4>{location}</h4>
              <p><strong>Bedrooms:</strong> {numberOfBedrooms}</p>
              <p><strong>Price:</strong> ${price} per night</p>
              <p><strong>Rating:</strong> {renderStars(rating)}</p>
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-bnb-heading">
          No suitable private homes match your search criteria. Please try adjusting the filters.
        </p>
      )}
    </div>
  );
};

export default PrivateBnB;

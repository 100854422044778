import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import logo from '../assets/images/logo.png';
import './styles/Home.css';
// import './styles/Results.css';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faMicrochip, faMapMarkerAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons'; // Importing Chevron icon for the button

import { FaApple, FaGooglePlay } from 'react-icons/fa';

const Spinner = () => (
  <div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
);

function Home() {

  useEffect(() => {
    document.title = 'Khah | Discover local stays near you';
  }, []);

  const [formData, setFormData] = useState({
    location: '',
    checkin: '',
    checkout: '',
    guests: 0,
  });

  const [result, setResult] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const searchResultsRef = useRef(null); // Create a ref for search results

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setResult([]);
    setLoading(true);

    try {
      // const response = await axios.post('http://localhost:3004/api/aisearch', formData);
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/aisearch', formData);
      if (response.data.error) {
        setError(response.data.error);
        console.log(error);
      } else {
        const resultsArray = response.data.result.split('\n').filter((line) => line.trim() !== '');
        setResult(resultsArray);
      }
    } catch (err) {
      setError('Something went wrong. Please try again later.');
      console.log(err);
    } finally {
      setLoading(false);
      // Scroll to the search results after loading
      searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFindNearMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Google Maps Geocoding API to convert coordinates to address
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBLYlw_XNJW_X7vpDZx4hjAh0YRbI48yuQ`
            );
            const data = await response.json();

            if (data.status === 'OK' && data.results.length > 0) {
              const addressComponents = data.results[0].address_components;

              // Extract desired components (neighborhood, city, country)
              let neighborhood = '';
              let city = '';
              let country = '';

              addressComponents.forEach((component) => {
                if (component.types.includes('sublocality') || component.types.includes('neighborhood')) {
                  neighborhood = component.long_name;
                }
                if (component.types.includes('locality')) {
                  city = component.long_name;
                }
                if (component.types.includes('country')) {
                  country = component.long_name;
                }
              });

              const location = `${city}, ${country}`;
              setFormData({
                ...formData,
                location: location,
              });
            } else {
              setError('Unable to retrieve location details.');
            }
          } catch (error) {
            console.error('Error with Google Geocoding API: ', error);
            setError('Unable to retrieve location details.');
          }
        },
        (error) => {
          console.error('Error getting location: ', error);
          setError('Unable to retrieve your location.');
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  // Scroll to the top of the form
  const scrollToTop = () => {
    const formElement = document.getElementById('searchContainer');
    formElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <div className="card">
        <div className="card-content">
          <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Find a <span style={{ color: '#0D92F4' }}>stay near you</span>
          </h4>
          <p style={{ textAlign: 'center', padding: '10px' }}>
            — where comfort meets convenience. Discover local stays, connect with welcoming hosts, and make every trip feel like home with <span style={{ fontStyle: 'italic' }}>Khah</span>.
          </p>
        </div>

        <div className="download-container">
          <button className="btn btn-dark">
                <FaApple size={20} className="icon-spacing" />
                <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" className="btn-link">
                  Download on iOS
                </a>
              </button>
          <button className="btn btn-google-success">
            <FaGooglePlay size={20} className="icon-spacing" />
            <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" className="btn-link">
              Download on Android
            </a>
          </button>
        </div>



        <div className="search-container2">
          {/* <button className="btn btn-dark">
                <FaApple size={20} className="icon-spacing" />
                <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" className="btn-link">
                  Download on iOS
                </a>
              </button> */}
            <Link to="/post" className="create-new-home-button2">
              + Upload a Stay
            </Link>
        </div>

        <div className="search-container" id="searchContainer">
          <form id="searchForm" className="search-form2" onSubmit={handleSubmit}>
            <div className="logo-section" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {/* <img src={logo} alt="logo" style={{ width: '40px', height: '40px', marginRight: '2px' }} /> */}
              <h2>Find accommodation near your location</h2>
            </div>

            <div className="form-group">
              <label htmlFor="location" className="location">
                <FontAwesomeIcon icon={faMap} /> Where are you?
              </label>
              <div className="input-icon-container">
                <FontAwesomeIcon icon={faMicrochip} className="ai-icon" />
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Enter details here"
                  required
                />
              </div>
            </div>

            {loading ? (
              <Spinner className="loading-spinner" />
            ) : (
              <button type="submit" className="btn-submit">Search using AI</button>
            )}
          </form>
        </div>

        <div ref={searchResultsRef} id="searchResults" className="search-results">
          {result.length > 0 && (
            <ul className="results-list">
              {result.map((item, index) => (
                <li key={index}><strong>{item}</strong></li>
              ))}
            </ul>
          )}
          <a href="mailto:khahtech2010@gmail.com?subject=Reservation request for my location:" className="mailto-link">
            Reserve your Bed & Breakfast
          </a>
        </div>
      </div>

      <button className="floating-button" onClick={scrollToTop} aria-label="Scroll to top">
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  );
}

export default Home;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

import TrackPageView from "./TrackPageView";

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';

import Stays from './pages/Stays';
import Post from './pages/Post';

import PrivateBnB from './pages/PrivateBnB';
import PrivateBnBDetails from './components/details/PrivateBnBDetails';
import PrivateBnBForm from './components/create/PrivateBnBForm';

import ApartmentDetails from './components/details/ApartmentDetails';
import ApartmentForm from './components/create/ApartmentForm';

import Contact from './pages/Contact';
import Terms from './pages/Terms';
// G-PL7C66F1W6
import NotFound from './pages/NotFound';
import './App.css'; // Ensure to include your global styles

const App = () => {

ReactGA.initialize("G-PL7C66F1W6"); // Replace with your Measurement ID

  return (
    <Router>
      <TrackPageView />
      <Navbar /> {/* Include the Navbar */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="/stays" element={<Stays />} />
          <Route path="/post" element={<Post />} />

          <Route exact path="/private_homes" element={<PrivateBnB />} />
          <Route path="/private_homes/:id" element={<PrivateBnBDetails />} />
          <Route path="/private_home/create" element={<PrivateBnBForm />} />
          <Route path="/private_home/edit/:id" element={<PrivateBnBForm />} />

          <Route path="/houses/:id" element={<ApartmentDetails />} />
          <Route path="/house/create" element={<ApartmentForm />} />

          {/* Add other routes here as needed */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './styles/Stays.css';

const DEFAULT_IMAGE = '/default-image.jpg';

// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <>
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span>
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span>
      ))}
    </>
  );
};

const AccommodationTabs = () => {
  const [activeTab, setActiveTab] = useState('private');
  const [homes, setHomes] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filters
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [religionFilter, setReligionFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const privateHomesRes = await axios.get('https://khah-184f534d3b81.herokuapp.com/api/private_homes');
        const servicedApartmentsRes = await axios.get('https://khah-184f534d3b81.herokuapp.com/api/houses');
        setHomes(privateHomesRes.data);
        setApartments(servicedApartmentsRes.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please check your network connectivity and refresh.');
        setLoading(false);
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const applyFilters = (item) => {
    return (
      !item.isBooked &&
      item.location.toLowerCase().includes(locationFilter.toLowerCase()) &&
      (bedroomFilter ? item.numberOfBedrooms >= bedroomFilter : true) &&
      (priceFilter ? item.price <= priceFilter : true) &&
      (religionFilter ? item.religion === religionFilter : true)
    );
  };

  const filteredData = activeTab === 'private' ? homes.filter(applyFilters) : apartments.filter(applyFilters);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="accommodation-tabs-container">
      {/* Tabs */}
      <div className="tabs">
        <button className={activeTab === 'private' ? 'active-tab' : ''} onClick={() => setActiveTab('private')}>
          Private Local Stays
        </button>
        <button className={activeTab === 'serviced' ? 'active-tab' : ''} onClick={() => setActiveTab('serviced')}>
          Serviced Apartments
        </button>
      </div>

      {/* Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
        <select
          value={religionFilter}
          onChange={(e) => setReligionFilter(e.target.value)}
        >
          <option value="">All Religions</option>
          <option value="Christian">Christian</option>
          <option value="Muslim">Muslim</option>
          <option value="Secular">Secular</option>
        </select>
      </div>

      {/* Listings */}
      {filteredData.length > 0 ? (
        <div className="accommodation-list">
          {filteredData.map(({ _id, location, numberOfBedrooms, price, rating, images, description }) => (
            <NavLink
              to={activeTab === 'private' ? `/private_homes/${_id}` : `/houses/${_id}`}  // Use different route based on the active tab
              key={_id}
              className="accommodation-card"
              onClick={() => {
                ReactGA.event({
                  category: 'Navigation',
                  action: 'View Accommodation Details',
                  label: `Accommodation ID: ${_id}`,
                });
              }}
            >
              <img
                src={images?.[0] || DEFAULT_IMAGE}
                alt={description || 'Accommodation'}
                className="accommodation-image"
              />
              <h4>{location}</h4>
              <p><strong>Bedrooms:</strong> {numberOfBedrooms}</p>
              <p><strong></strong><span style={{ color: 'red', fontWeight: 'bold' }}> ${price} per night</span></p>
              <p><strong>Rating:</strong> <span style={{ color: 'gold' }}>{renderStars(rating)}</span></p>

            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-accommodation-heading">No results match your filters. Please adjust and try again.</p>
      )}
    </div>
  );
};

export default AccommodationTabs;

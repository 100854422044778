import React, { useState } from 'react';
import axios from 'axios';
import { FaCalendarAlt } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel'; // Import the Carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import Carousel styles
import './styles/PrivateBnBForm.css'; // Import your CSS file for styling

const ApartmentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    description: '',
    numberOfBedrooms: 1,
    numberOfSpaces: 1,
    price: '',
    phoneNumber: '',
    mealsProvided: 'No', 
    petsAllowed: 'No', 
    partysAllowed: 'No', 
    gym: 'Available', 
    porch: 'Back Porch', 
    pool: 'Available', 
    nameOfHost: '', 
    facilities: '',
    securityDetails: 'Gated',
    proximityToMalls: '',
    proximityToAirport: '',
    availableDate: '',
    images: [],
    typeOfHouse: 'Fully Furnished Apartment',
    isBooked: true,
    rating: 4,
  });

  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoading] = useState(false); // New state for spinner

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files);
  };

  const handleBookingChange = (e) => {
    setFormData({
      ...formData,
      isBooked: e.target.value === 'true', // Convert to boolean
    });
  };

  const handleRatingChange = (value) => {
    setFormData({
      ...formData,
      rating: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner when form submission starts
    const formDataToSend = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      formDataToSend.append('images', selectedImages[i]);
    }
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
// https://khah-184f534d3b81.herokuapp.com      http://localhost:3004
    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/house/create', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(response.data.msg);
      // Reset form after successful submission
      setFormData({
        name: '',
        location: '',
        description: '',
        numberOfBedrooms: 1,
        numberOfSpaces: 1,
        price: '',
        phoneNumber: '',
        mealsProvided: '', 
        petsAllowed: '', 
        partysAllowed: '', 
        gym: '', 
        porch: '', 
        pool: '', 
        nameOfHost: '', 
        facilities: '',
        securityDetails: '',
        proximityToMalls: '',
        proximityToAirport: '',
        availableDate: '',
        images: [],
        typeOfHouse: 'Fully Furnished Apartment',
        isBooked: true,
        rating: 4,
      });
      setSelectedImages(null);
    } catch (error) {
      console.error(error);
      alert('Error posting the private home');
    } finally {
      setLoading(false); // Hide spinner after form submission completes
    }
  };

  // Convert selected images to URLs for display in the carousel
  const imageUrls = selectedImages ? Array.from(selectedImages).map((file) => URL.createObjectURL(file)) : [];

  return (
    <form onSubmit={handleSubmit} className="private-home-form">
      <h2>List your Apartment</h2> {/* Form title */}
      <div>
      <div>
        <label>Name of Apartment:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter the apartment name" // Placeholder
          required
        />
      </div>
        <label>Location:</label>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          placeholder="Enter the location of your home" // Placeholder
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Provide a brief description of your home" // Placeholder
          required
        ></textarea>
      </div>
      <div>
        <label>Facilities:</label>
        <input
          type="text"
          name="facilities"
          value={formData.facilities}
          onChange={handleChange}
          placeholder="e.g., Wi-Fi, Kitchen, Parking" // Placeholder
        />
      </div>
      <div>
        <label>Security Details:</label>
        <input
          type="text"
          name="securityDetails"
          value={formData.securityDetails}
          onChange={handleChange}
          placeholder="e.g., Gated, CCTV, Gate-Man" // Placeholder
        />
      </div>
      <div>
        <label>Number of Bedrooms:</label>
        <input
          type="number"
          name="numberOfBedrooms"
          value={formData.numberOfBedrooms}
          onChange={handleChange}
          min="1"
          placeholder="1" // Placeholder
        />
      </div>
      <div>
        <label>Number of Units:</label>
        <input
          type="number"
          name="numberOfSpaces"
          value={formData.numberOfSpaces}
          onChange={handleChange}
          min="1"
          placeholder="1" // Placeholder
        />
      </div>
      <div>
        <label>Type of Apartment:</label>
        <select name="typeOfHouse" value={formData.typeOfHouse} onChange={handleChange}>
          <option value="Fully Furinshed Apartment">Fully Furinshed Apartment</option>
          <option value="Semi-Furnished Apartment">Semi-Furnished Apartment</option>
          <option value="Unfurnished Apartment">Unfurnished Apartment</option>
          <option value="Flat">Flat</option>
        </select>
      </div>
      <div>
        <label>Price($):</label>
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          placeholder="Enter the price per night" // Placeholder
          required
        />
      </div>
      <div>
        <label>Phone Number:</label>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Enter your contact number" // Placeholder
          required
        />
      </div>
      <div>
        <label>Name of Host:</label>
        <input
          type="text"
          name="nameOfHost"
          value={formData.nameOfHost}
          onChange={handleChange}
          placeholder="Enter your name" // Placeholder
          required
        />
      </div>
      <div>
        <label>Meals Provided:</label>
        <select name="mealsProvided" value={formData.mealsProvided} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Pets Allowed:</label>
        <select name="petsAllowed" value={formData.petsAllowed} onChange={handleChange}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Party's Allowed:</label>
        <select name="partysAllowed" value={formData.partysAllowed} onChange={handleChange}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Gym:</label>
        <select name="gym" value={formData.gym} onChange={handleChange}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Porch:</label>
        <select name="porch" value={formData.porch} onChange={handleChange}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Pool:</label>
        <select name="pool" value={formData.pool} onChange={handleChange}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
        </select>
      </div>
      <div>
        <label>Proximity to Malls (in km):</label>
        <input
          type="number"
          name="proximityToMalls"
          value={formData.proximityToMalls}
          onChange={handleChange}
          placeholder="Distance to nearest mall" // Placeholder
        />
      </div>
      <div>
        <label>Proximity to Airport (in km):</label>
        <input
          type="number"
          name="proximityToAirport"
          value={formData.proximityToAirport}
          onChange={handleChange}
          placeholder="Distance to nearest airport" // Placeholder
        />
      </div>
      <div>
        <label>
          Available Date:
          <input
            type="date"
            name="availableDate"
            value={formData.availableDate}
            onChange={handleChange}
            required
          />
          <FaCalendarAlt />
        </label>
      </div>
      {/* <div>
        <label>Is Booked:</label>
        <label>
          <input
            type="radio"
            name="isBooked"
            value="true"
            checked={formData.isBooked === true}
            onChange={handleBookingChange}
          />
          <span> Yes</span>
        </label>
        <label>
          <input
            type="radio"
            name="isBooked"
            value="false"
            checked={formData.isBooked === false}
            onChange={handleBookingChange}
          />
          <span> No</span>
        </label>
      </div> */}
      <div>
        <label>Rating:</label>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} onClick={() => handleRatingChange(star)}>
              {star <= formData.rating ? '★' : '☆'}
            </span>
          ))}
        </div>
      </div>
      <div>
        <label>Upload Images:</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          required
        />
      </div>
      {/* Carousel for selected images */}
      {imageUrls.length > 0 && (
        <div className="image-carousel">
          <Carousel showThumbs={false} infiniteLoop autoFocus>
            {imageUrls.map((url, index) => (
              <div key={index}>
                <img src={url} alt={`Selected ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      
      {/* Spinner and Submit Button */}
      <div className="submit-container">
        {loading ? (
          <div className="spinner"></div> // Display spinner when loading
        ) : (
          <button type="submit">Submit</button> // Show button when not loading
        )}
      </div>
    </form>
  );
};

export default ApartmentForm;

import React, { useState, useEffect } from 'react';
import './styles/Contact.css'; // Ensure the path is correct for your CSS

const Contact = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'Khah | Contact';
  }, []);

  const handleEmailClick = () => {
    const mailtoLink = `mailto:khahtech2010@gmail.com?subject=Customer%20Support%20Request&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-container">
      <h3>Contact Us</h3>
      <p>
        Have any questions or need assistance? Feel free to reach out to us! You can send us a message below or email us directly.
      </p>

      <textarea
        className="message-input"
        placeholder="Type your message here..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows="5"
      />

      <button className="send-button" onClick={handleEmailClick}>
        Send Message
      </button>

      <p>
        Or email us directly by: <a href="mailto:khahtech2010@gmail.com" className="email-link">clicking here.</a>
      </p>
      <p>
        To talk to a personal assistant: <a href="tel:+260777210641" className="phone-link">click here to call</a>
      </p>
      <p>We look forward to hearing from you!</p>
    </div>
  );
};

export default Contact;
